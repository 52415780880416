<!--
    Copyright 2021 Rustici Software

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

        http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
 -->
<template>
    <b-row>
        <b-col>
            <h5>About</h5>
            <p>
                CATAPULT (cmi5 Advanced Testing Application and Player Underpinning Learning Technologies) is a project funded by ADL to develop tools and resources to support the adoption of cmi5 and xAPI across the Department of Defense (DoD) enterprise. The cornerstone tool within this project is the development and delivery of cmi5 Prototype Software (PTS) including a cmi5 player test suite, a cmi5 player and a cmi5 Content Test Suite (CTS).
            </p>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: "AdminAbout"
    };
</script>

<style lang="scss" scoped>
</style>
