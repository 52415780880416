import { render, staticRenderFns } from "./testNew.vue?vue&type=template&id=ecc7a856&scoped=true&"
import script from "./testNew.vue?vue&type=script&lang=js&"
export * from "./testNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecc7a856",
  null
  
)

export default component.exports